import { grey } from './grey'
import { green } from './green'
import { lightGreen } from './light_green'

export const palette = {
  black: '#000000',
  green,
  grey,
  lightGreen,
  white: '#ffffff',
}
