import React from 'react'
import styled from 'styled-components'

export const Logo = (props) => {
  const className = props.className
  const size = props.size || '100px'

  return (
    <Icon
      className={className}
      size={size}
      viewBox='0 0 150 150'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m150 75c0 41.4-33.6 75-75 75s-75-33.6-75-75 33.6-75 75-75 75 33.6 75 75z' />
      <g fill='#fff'>
        <path d='m42.9 85.1s1.4 11.2-2.6 12-11.6-19.6-7.5-22.3c1.8-1.2 6.8.7 6.8.7z' />
        <path d='m109.3 85.4s-1.7 10.9 2.4 10.4c4.1-.4 8.2-21.3 6.6-22.6-3-2.5-8.1 1.7-8.1 1.7z' />
        <path d='m77.8 31.3c-22.8 1.4-39.9 10.5-36.9 41.2s9.2 46 12.7 48.7c3.5 2.6 18.8 8.3 26.3 8.3s22.4-8.8 25-11.8c2.6-3.1 5.7-41.6 4.8-50.8s-3.9-37.4-31.9-35.6z' />
      </g>
      <path d='m43.8 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m45.5 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m47.2 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m48.9 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m50.6 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m52.3 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m54 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m55.7 97.3.1.1s-.1 0-.1-.1z' />
      <path d='m57.5 97.3.1.1c-.1 0-.2 0-.1-.1z' />
      <path d='m59.2 97.3.1.1c-.1 0-.2 0-.1-.1z' />
      <path d='m60.9 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m62.6 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m64.3 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m66 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m67.7 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m69.4 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m71.1 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m72.8 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m74.5 97.3.1.1s-.1 0-.1-.1z' />
      <path d='m76.3 97.3.1.1c-.1 0-.2 0-.1-.1z' />
      <path d='m78 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m79.7 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m81.4 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m83.1 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m84.8 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m86.5 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m88.2 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m89.9 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m91.6 97.3.1.1s-.1 0-.1-.1z' />
      <path d='m93.3 97.3.1.1s-.1 0-.1-.1z' />
      <path d='m95.1 97.3.1.1c-.1 0-.2 0-.1-.1z' />
      <path d='m96.8 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m98.5 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m100.2 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m101.9 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m103.6 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m105.3 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m107 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m108.7 97.3.1.1c-.1 0-.1 0-.1-.1z' />
      <path d='m44.7 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m46.5 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m48.2 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m49.9 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m51.6 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m53.3 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s.1.1.1.2z' />
      <path d='m55 98.6c0 .1 0 .2-.1.1v-.1c-.1-.1.1-.1.1 0z' />
      <path d='m56.7 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m58.4 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m60.1 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m61.8 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m63.6 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m65.3 98.6c0 .1 0 .2-.1.1v-.1c-.1-.1 0-.1.1 0z' />
      <path d='m67 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s0 .1.1.2z' />
      <path d='m68.7 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s.1.1.1.2z' />
      <path d='m70.4 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s.1.1.1.2z' />
      <path d='m72.1 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s.1.1.1.2z' />
      <path d='m73.8 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s.1.1.1.2z' />
      <path d='m75.5 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s.1.1.1.2z' />
      <path d='m77.2 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m78.9 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2s.1.1.1.2z' />
      <path d='m80.6 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m82.4 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2-.1 0 0 0 0 0z' />
      <path d='m84.1 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m85.8 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m87.5 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m89.2 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m90.9 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m92.6 98.6c0 .1 0 .2-.1.1v-.1c-.1-.1.1-.1.1 0z' />
      <path d='m94.3 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m96 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m97.7 98.6c0 .1 0 .2-.1.1v-.1c0-.1.1-.1.1 0z' />
      <path d='m99.4 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2.1.1.1.1.1.2z' />
      <path d='m101.2 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m102.9 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m104.6 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1 0 .1.1.2z' />
      <path d='m106.3 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m108 98.6c0 .1 0 .2-.1.1v-.1c0-.1 0-.1 0-.2 0 .1.1.1.1.2z' />
      <path d='m43.7 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m45.5 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m47.2 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m48.9 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m50.6 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m52.3 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m54 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m55.7 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m57.4 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m59.1 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m60.8 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m62.5 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m64.3 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m66 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m67.7 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m69.4 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m71.1 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m72.8 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m74.5 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m76.2 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m77.9 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m79.6 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m81.3 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m83.1 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m84.8 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m86.5 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m88.2 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m89.9 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m91.6 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m93.3 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m95 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m96.7 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m98.4 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m100.1 100c0-.1 0-.2 0-.2h.1v.2s0 0-.1 0z' />
      <path d='m101.9 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m103.6 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m105.3 100c0-.1 0-.2 0-.2h.1v.2c-.1 0-.1 0-.1 0z' />
      <path d='m107 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m108.7 100c0-.1 0-.2 0-.2h.1v.2z' />
      <path d='m44.6 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m46.3 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m48.1 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m49.8 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m51.5 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m53.2 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m54.9 101c.1 0 .1 0 .1.1 0 .4-.3.1-.1-.1z' />
      <path d='m56.6 101c.1 0 .1 0 .1.1 0 .4-.3.1-.1-.1z' />
      <path d='m58.3 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m60 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m61.7 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m63.4 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m65.1 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m66.9 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m68.6 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m70.3 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m72 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m73.7 101c.1 0 .1 0 .1.1 0 .4-.3.1-.1-.1z' />
      <path d='m75.4 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m77.1 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m78.8 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m80.5 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m82.2 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m83.9 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m85.7 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m87.4 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m89.1 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m90.8 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m92.5 101c.1 0 .1 0 .1.1 0 .4-.3.1-.1-.1z' />
      <path d='m94.2 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m95.9 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m97.6 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m99.3 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m101 101c.1 0 .1 0 .1.1.1.4-.3.1-.1-.1z' />
      <path d='m102.8 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m104.5 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m106.2 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m107.9 101c.1 0 .1 0 .1.1 0 .4-.4.1-.1-.1z' />
      <path d='m43.8 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m45.5 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m47.2 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m48.9 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m50.6 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m52.3 102.3c.1-.1.1 0 .2.1v.1c0 .4-.5 0-.2-.2z' />
      <path d='m54 102.3c.1-.1.1 0 .2 0v.1c.1.5-.5.1-.2-.1z' />
      <path d='m55.7 102.3c.1-.1.1 0 .2 0v.1c.1.5-.5.1-.2-.1z' />
      <path d='m57.4 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m59.1 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m60.9 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m62.6 102.3c.1-.1.1-.1.2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m64.3 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m66 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m67.7 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m69.4 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m71.1 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m72.8 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m74.5 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m76.2 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m77.9 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m79.7 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m81.4 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m83.1 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m84.8 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m86.5 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m88.2 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m89.9 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m91.6 102.3c.1-.1.1 0 .2 0v.1c.1.5-.5.1-.2-.1z' />
      <path d='m93.3 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m95 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m96.7 102.3c.1-.1.1 0 .2 0v.1c.1.5-.4.1-.2-.1z' />
      <path d='m98.5 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m100.2 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m101.9 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m103.6 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m105.3 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m107 102.3c.1-.1.1-.1.2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m108.7 102.3c.1-.1.1 0 .2 0v.1c0 .5-.5.1-.2-.1z' />
      <path d='m44.6 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m46.3 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m48.1 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m49.8 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m51.5 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m53.2 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m54.9 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m56.6 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m58.3 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m60 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m61.7 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m63.4 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m65.1 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m66.9 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m68.6 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m70.3 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m72 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m73.7 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m75.4 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m77.1 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m78.8 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m80.5 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m82.2 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m83.9 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m85.7 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m87.4 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m89.1 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m90.8 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m92.5 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m94.2 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m95.9 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m97.6 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m99.3 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m101 103.5c.3-.1.1.7-.1.4 0-.1 0-.3.1-.4z' />
      <path d='m102.8 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m104.5 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m106.2 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0-.1 0 0 0z' />
      <path d='m107.9 103.5c.3-.1.1.7-.1.4-.1-.1-.1-.3.1-.4-.1 0 0 0 0 0z' />
      <path d='m43.8 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m45.5 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m47.2 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m48.9 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m50.6 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m52.3 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m54 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m55.7 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m57.4 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m59.1 104.7c.2-.1.2 0 .2.2.1.7-.5 0-.2-.2z' />
      <path d='m60.9 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m62.6 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m64.3 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m66 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m67.7 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m69.4 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m71.1 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m72.8 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m74.5 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m76.2 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m77.9 104.7c.2-.1.2 0 .2.2.1.7-.5 0-.2-.2z' />
      <path d='m79.7 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m81.4 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m83.1 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m84.8 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m86.5 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m88.2 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m89.9 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m91.6 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m93.3 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m95 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m96.7 104.7c.2-.1.2 0 .2.2.1.7-.5 0-.2-.2z' />
      <path d='m98.5 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m100.2 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m101.9 104.7c.2-.1.2 0 .2.2 0 .7-.6 0-.2-.2z' />
      <path d='m103.6 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m105.3 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m107 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m108.7 104.7c.2-.1.2 0 .2.2.1.7-.6 0-.2-.2z' />
      <path d='m44.6 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m46.3 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m48.1 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2 0-.3.2-.3z' />
      <path d='m49.8 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1 0-.2.2-.2z' />
      <path d='m51.5 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1 0-.2.2-.2z' />
      <path d='m53.2 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2 0-.3.2-.3z' />
      <path d='m54.9 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2.1-.3.2-.3z' />
      <path d='m56.6 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m58.3 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m60 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m61.7 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m63.4 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m65.1 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m66.9 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2 0-.3.2-.3z' />
      <path d='m68.6 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1 0-.2.2-.2z' />
      <path d='m70.3 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2 0-.3.2-.3z' />
      <path d='m72 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2.1-.3.2-.3z' />
      <path d='m73.7 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1.1-.2.2-.2z' />
      <path d='m75.4 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1.1-.2.2-.2z' />
      <path d='m77.1 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m78.8 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m80.5 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m82.2 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m83.9 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m85.7 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1 0-.2.2-.2z' />
      <path d='m87.4 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2 0-.3.2-.3z' />
      <path d='m89.1 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c0-.1 0-.2.2-.2z' />
      <path d='m90.8 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2.1-.3.2-.3z' />
      <path d='m92.5 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1.1-.2.2-.2z' />
      <path d='m94.2 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m95.9 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m97.6 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m99.3 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 .1-.1.1-.2.2-.2z' />
      <path d='m101 106c.1 0 .2 0 .2.2s-.1.3-.2.3-.2-.1-.2-.2c.1-.2.1-.3.2-.3z' />
      <path d='m102.8 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1 0-.2.2-.2z' />
      <path d='m104.5 106c.1-.1.2 0 .2.1v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.2c0-.2 0-.3.2-.3z' />
      <path d='m106.2 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1 0-.2.2-.2z' />
      <path d='m107.9 106c.1-.1.2 0 .2.1v.1c0 .4-.4.4-.4 0 0-.1 0-.2.2-.2z' />
      <path d='m43.8 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m45.5 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m47.2 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m48.9 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m50.6 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m52.3 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m54 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m55.7 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m57.5 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m59.2 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m60.9 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m62.6 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m64.3 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m66 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m67.7 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m69.4 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m71.1 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m72.8 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m74.5 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m76.3 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m78 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m79.7 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m81.4 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m83.1 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m84.8 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m86.5 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m88.2 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m89.9 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m91.6 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m93.3 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m95.1 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m96.8 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m98.5 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m100.2 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1 0-.1.1-.1z' />
      <path d='m101.9 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m103.6 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m105.3 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m107 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4 0-.1.1-.1.1-.1z' />
      <path d='m108.7 107.2c.4-.1.2 1-.1.5-.1-.1-.1-.3 0-.4s.1-.1.1-.1z' />
      <path d='m44.6 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m46.3 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m48.1 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1-.1-.2.1-.3z' />
      <path d='m49.8 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2-.1-.3.1-.4z' />
      <path d='m51.5 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2-.1-.3.1-.4z' />
      <path d='m53.2 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1-.1-.2.1-.3z' />
      <path d='m54.9 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m56.6 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1 0-.2.1-.3z' />
      <path d='m58.3 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m60 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1 0-.2.1-.3z' />
      <path d='m61.7 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m63.4 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m65.1 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1 0-.2.1-.3z' />
      <path d='m66.9 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2-.1-.3.1-.4z' />
      <path d='m68.6 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1-.1-.2.1-.3z' />
      <path d='m70.3 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1-.1-.2.1-.3z' />
      <path d='m72 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m73.7 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m75.4 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m77.1 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m78.8 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m80.5 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m82.2 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m83.9 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m85.7 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1-.1-.2.1-.3z' />
      <path d='m87.4 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2-.1-.3.1-.4z' />
      <path d='m89.1 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1-.1-.2.1-.3z' />
      <path d='m90.8 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m92.5 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m94.2 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1 0-.2.1-.3z' />
      <path d='m95.9 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m97.6 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m99.3 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m101 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2 0-.3.1-.4z' />
      <path d='m102.8 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.3-.2.3s-.2-.1-.2-.3c-.1-.1-.1-.2.1-.3z' />
      <path d='m104.5 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2-.1-.3.1-.4z' />
      <path d='m106.2 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2-.1-.3.1-.4z' />
      <path d='m107.9 108.4c.1-.1.2 0 .3.2v.1c0 .2-.1.4-.2.4s-.2-.1-.2-.3c-.1-.2-.1-.3.1-.4z' />
      <path d='m43.8 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m45.5 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m47.2 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m48.9 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m50.6 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m52.3 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.2-.2.2-.2z' />
      <path d='m54.1 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m55.8 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m57.5 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m59.2 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m60.9 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m62.6 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m64.3 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m66 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m67.7 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m69.4 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m71.1 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.2-.2.2-.2z' />
      <path d='m72.9 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m74.6 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m76.3 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m78 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m79.7 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m81.4 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m83.1 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m84.8 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m86.5 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m88.2 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.2-.2.2-.2z' />
      <path d='m90 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m91.7 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m93.4 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m95.1 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m96.8 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m98.5 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m100.2 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m101.9 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m103.6 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m105.3 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.1-.2.2-.2z' />
      <path d='m107 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1.1-.2.2-.2.2-.2z' />
      <path d='m108.8 109.7c.2 0 .3.1.3.3 0 .6-.6.5-.5-.1 0-.2.1-.2.2-.2z' />
      <path d='m44.6 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m46.3 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m48 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0z' />
      <path d='m49.7 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m51.4 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m53.1 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m54.8 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m56.5 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m58.3 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m60 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m61.7 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m63.4 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m65.1 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0z' />
      <path d='m66.8 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m68.5 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0z' />
      <path d='m70.2 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m71.9 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m73.6 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m75.3 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m77.1 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m78.8 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m80.5 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m82.2 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m83.9 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m85.6 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0z' />
      <path d='m87.3 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m89 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0z' />
      <path d='m90.7 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m92.4 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m94.1 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m95.9 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m97.6 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 0-.1.1-.1.2-.2z' />
      <path d='m99.3 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m101 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0z' />
      <path d='m102.7 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m104.4 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.1-.1.2-.2z' />
      <path d='m106.1 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0z' />
      <path d='m107.8 110.9c.2-.1.3 0 .3.2.1.7-.6.7-.5 0 .1-.1.2-.1.2-.2z' />
      <path d='m43.7 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m45.5 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m47.2 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m48.9 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m50.6 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m52.3 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m54 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m55.7 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m57.4 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m59.1 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m60.8 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m62.5 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m64.3 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m66 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m67.7 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m69.4 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m71.1 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m72.8 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m74.5 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m76.2 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m77.9 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m79.6 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m81.3 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m83.1 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m84.8 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m86.5 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m88.2 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m89.9 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m91.6 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m93.3 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m95 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m96.7 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m98.4 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1.1-.1.1-.1z' />
      <path d='m100.1 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6.1-.1.1-.1.1-.1z' />
      <path d='m101.9 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m103.6 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m105.3 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m107 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m108.7 112.2c.6-.2.3 1.2-.1.7-.1-.1-.1-.4 0-.6 0-.1 0-.1.1-.1z' />
      <path d='m44.6 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m46.3 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m48 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m49.7 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m51.4 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m53.1 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m54.9 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1 0-.3.2-.3z' />
      <path d='m56.6 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1 0-.3.2-.3z' />
      <path d='m58.3 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m60 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m61.7 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m63.4 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m65.1 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m66.8 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m68.5 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m70.2 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m71.9 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m73.7 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1 0-.3.2-.3z' />
      <path d='m75.4 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1 0-.3.2-.3z' />
      <path d='m77.1 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m78.8 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m80.5 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m82.2 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m83.9 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m85.6 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m87.3 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m89 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m90.7 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m92.5 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1 0-.3.2-.3z' />
      <path d='m94.2 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m95.9 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m97.6 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m99.3 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m101 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m102.7 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m104.4 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m106.1 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m107.8 113.4c.2-.1.3.1.4.3.1.6-.6.8-.6 0 0-.1.1-.3.2-.3z' />
      <path d='m43.7 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m45.5 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3-.1-.1 0-.2.2-.3z' />
      <path d='m47.2 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3-.1-.1 0-.2.2-.3z' />
      <path d='m48.9 114.6c.2-.1.3 0 .4.2v.4c-.1.2-.3.3-.4.2s-.2-.3-.2-.5c0-.1 0-.2.2-.3z' />
      <path d='m50.6 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m52.3 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m54 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m55.7 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m57.4 114.6c.2-.1.3 0 .4.3v.4c-.1.4-.6.4-.6-.3 0-.2.1-.3.2-.4z' />
      <path d='m59.1 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m60.8 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m62.5 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m64.3 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3-.1-.1 0-.2.2-.3z' />
      <path d='m66 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3-.1-.1 0-.2.2-.3z' />
      <path d='m67.7 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1 0-.2.2-.3z' />
      <path d='m69.4 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m71.1 114.6c.2-.1.3 0 .4.2v.4c-.1.2-.3.3-.4.2s-.2-.3-.2-.5c0-.1.1-.2.2-.3z' />
      <path d='m72.8 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m74.5 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m76.2 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m77.9 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m79.6 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m81.3 114.6c.2-.1.3 0 .4.2v.4c-.1.2-.3.3-.4.2s-.2-.3-.2-.5c0-.1.1-.2.2-.3z' />
      <path d='m83.1 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3-.1-.1 0-.2.2-.3z' />
      <path d='m84.8 114.6c.2-.1.3 0 .4.3v.4c-.1.4-.6.4-.6-.3-.1-.2 0-.3.2-.4z' />
      <path d='m86.5 114.6c.2-.1.3 0 .4.2v.4c-.1.2-.3.3-.4.2s-.2-.3-.2-.5c0-.1.1-.2.2-.3z' />
      <path d='m88.2 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m89.9 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m91.6 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m93.3 114.6c.2-.1.3 0 .4.3v.4c-.1.4-.6.4-.6-.3 0-.2.1-.3.2-.4z' />
      <path d='m95 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m96.7 114.6c.2-.1.3 0 .4.2v.4c-.1.2-.3.3-.4.2s-.2-.3-.2-.5c0-.1.1-.2.2-.3z' />
      <path d='m98.4 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m100.1 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m101.9 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3-.1-.1 0-.2.2-.3z' />
      <path d='m103.6 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3-.1-.1 0-.2.2-.3z' />
      <path d='m105.3 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m107 114.6c.2-.1.3 0 .4.2v.4c-.1.4-.6.4-.6-.3 0-.1.1-.2.2-.3z' />
      <path d='m108.7 114.6c.2-.1.3 0 .4.2v.4c-.1.2-.3.3-.4.2s-.2-.3-.2-.5c0-.1.1-.2.2-.3z' />
      <path d='m44.6 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m46.3 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m48 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m49.7 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m51.5 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2 0-.3.2-.3z' />
      <path d='m53.2 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m54.9 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m56.6 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m58.3 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m60 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m61.7 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m63.4 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m65.1 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m66.8 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m68.5 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m70.3 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2 0-.3.2-.3z' />
      <path d='m72 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m73.7 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m75.4 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m77.1 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m78.8 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m80.5 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m82.2 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m83.9 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m85.6 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m87.3 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m89.1 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m90.8 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m92.5 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m94.2 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m95.9 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m97.6 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m99.3 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m101 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m102.7 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m104.4 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m106.2 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2 0-.3.2-.3z' />
      <path d='m107.9 115.9c.1-.1.3 0 .4.3.2.8-.8.9-.6 0 0-.2.1-.3.2-.3z' />
      <path d='m109.3 116.1v.4c0-.1 0-.2 0-.4z' />
      <path d='m43.8 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m45.5 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m47.2 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m48.9 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m50.6 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m52.3 117.1c.4 0 .5.8.1 1-.3.2-.6-.9-.1-1z' />
      <path d='m54.1 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m55.8 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m57.5 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m59.2 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m60.9 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m62.6 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m64.3 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m66 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m67.7 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m69.4 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m71.1 117.1c.4 0 .5.8.1 1-.3.2-.6-.9-.1-1z' />
      <path d='m72.9 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m74.6 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m76.3 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m78 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m79.7 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m81.4 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m83.1 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m84.8 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m86.5 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m88.2 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m90 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m91.7 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m93.4 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m95.1 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m96.8 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m98.5 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m100.2 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m101.9 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m103.6 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m105.3 117.1c.4 0 .5.8.1 1s-.6-.9-.1-1z' />
      <path d='m107 117.1c.4 0 .5.8.1 1-.3.2-.6-.9-.1-1z' />
      <path d='m108.8 117.1c.4 0 .5.8.1 1s-.7-.9-.1-1z' />
      <path d='m44.6 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m46.3 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m48 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m49.7 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m51.4 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m53.1 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m54.8 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m56.5 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m58.2 118.4c.6-.3.6 1.2 0 1-.2-.1-.3-.9 0-1z' />
      <path d='m59.9 118.4c.6-.3.6 1.2 0 1-.2-.1-.3-.9 0-1z' />
      <path d='m61.7 118.4c.6-.3.6 1.2 0 1-.3-.1-.4-.9 0-1z' />
      <path d='m63.4 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m65.1 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m66.8 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m68.5 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m70.2 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m71.9 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m73.6 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m75.3 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m77 118.4c.6-.3.6 1.2 0 1-.2-.1-.3-.9 0-1z' />
      <path d='m78.7 118.4c.6-.3.6 1.2 0 1-.2-.1-.3-.9 0-1z' />
      <path d='m80.5 118.4c.6-.3.6 1.2 0 1-.3-.1-.4-.9 0-1z' />
      <path d='m82.2 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m83.9 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m85.6 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m87.3 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m89 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m90.7 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m92.4 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m94.1 118.4c.6-.3.6 1.2 0 1-.2-.1-.3-.9 0-1z' />
      <path d='m95.8 118.4c.6-.3.6 1.2 0 1-.2-.1-.3-.9 0-1z' />
      <path d='m97.5 118.4c.6-.3.6 1.2 0 1-.2-.1-.3-.9 0-1z' />
      <path d='m99.3 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m101 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m102.7 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m104.4 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m106.1 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m107.8 118.4c.6-.3.6 1.2 0 1-.2-.1-.4-.9 0-1z' />
      <path d='m109.3 118.6v.6c0-.2 0-.5 0-.6z' />
      <path d='m43.7 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m45.4 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m47.1 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m48.9 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m50.6 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m52.3 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m54 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m55.7 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m57.4 119.6c.6-.2.6 1.2 0 1-.3 0-.4-.8 0-1z' />
      <path d='m59.1 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m60.8 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m62.5 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m64.2 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m65.9 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m67.7 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m69.4 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m71.1 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m72.8 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m74.5 119.6c.6-.2.6 1.2 0 1-.3 0-.4-.8 0-1z' />
      <path d='m76.2 119.6c.6-.2.6 1.2 0 1-.3 0-.4-.8 0-1z' />
      <path d='m77.9 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m79.6 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m81.3 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m83 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m84.7 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m86.5 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m88.2 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m89.9 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m91.6 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m93.3 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m95 119.6c.6-.2.6 1.2 0 1-.3 0-.4-.8 0-1z' />
      <path d='m96.7 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m98.4 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m100.1 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m101.8 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m103.5 119.6c.6-.2.6 1.2 0 1-.2 0-.4-.8 0-1z' />
      <path d='m105.3 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m107 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m108.7 119.6c.6-.2.6 1.2 0 1-.3 0-.5-.8 0-1z' />
      <path d='m44.6 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m46.3 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m48 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m49.7 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m51.4 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m53.1 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m54.9 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6-.1-.3.1-.5.3-.5z' />
      <path d='m56.6 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m58.3 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m60 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m61.7 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m63.4 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m65.1 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m66.8 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m68.5 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m70.2 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m71.9 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m73.7 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m75.4 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m77.1 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m78.8 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m80.5 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m82.2 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m83.9 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m85.6 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m87.3 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m89 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m90.7 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m92.5 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m94.2 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m95.9 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m97.6 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m99.3 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m101 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m102.7 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m104.4 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.1-.5.3-.5z' />
      <path d='m106.1 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m107.8 120.9c.1 0 .2 0 .3.1.2.2.1.9-.2 1-.2 0-.4-.1-.4-.6 0-.3.2-.5.3-.5z' />
      <path d='m109.3 121v.7c-.1-.1-.1-.5 0-.7z' />
      <path d='m43.8 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1.1-.9-.3-1z' />
      <path d='m45.5 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m47.2 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m48.9 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m50.6 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m52.3 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m54 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m55.7 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m57.4 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m59.1 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m60.9 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1.1-.9-.3-1z' />
      <path d='m62.6 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.5.7-1.1-.9-.3-1z' />
      <path d='m64.3 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m66 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m67.7 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m69.4 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m71.1 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m72.8 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m74.5 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m76.2 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m77.9 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m79.7 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1.1-.9-.3-1z' />
      <path d='m81.4 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m83.1 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m84.8 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m86.5 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m88.2 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m89.9 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m91.6 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m93.3 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m95 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m96.7 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m98.5 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1.1-.9-.3-1z' />
      <path d='m100.2 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m101.9 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.5.7-1-.9-.3-1z' />
      <path d='m103.6 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m105.3 122.1c.2 0 .4.2.4.5 0 .2 0 .4-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m107 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m108.7 122.1c.2 0 .4.2.4.5 0 .2 0 .3-.1.5-.4.7-1-.9-.3-1z' />
      <path d='m44.6 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m46.3 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m48.1 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m49.8 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m51.5 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m53.2 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m54.9 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m56.6 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m58.3 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m60 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m61.7 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m63.4 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m65.1 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m66.9 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m68.6 123.3c.6-.1.6 1.2 0 1.2s-.6-1.1 0-1.2z' />
      <path d='m70.3 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m72 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m73.7 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m75.4 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m77.1 123.3c.6-.1.6 1.2 0 1.2s-.5-1.1 0-1.2z' />
      <path d='m78.8 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m80.5 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m82.2 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m83.9 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m85.7 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m87.4 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m89.1 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m90.8 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m92.5 123.3c.6-.1.6 1.2 0 1.2s-.5-1.1 0-1.2z' />
      <path d='m94.2 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m95.9 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m97.6 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m99.3 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m101 123.3c.6-.1.6 1.2 0 1.2-.4 0-.5-1.1 0-1.2z' />
      <path d='m102.8 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m104.5 123.3c.6-.1.6 1.2 0 1.2s-.6-1.1 0-1.2z' />
      <path d='m106.2 123.3c.6-.1.6 1.2 0 1.2-.5 0-.6-1.1 0-1.2z' />
      <path d='m107.9 123.3c.6-.1.6 1.2 0 1.2-.5 0-.5-1.1 0-1.2z' />
      <path d='m109.3 123.5v.8c-.1-.1-.1-.6 0-.8z' />
      <path d='m43.7 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m45.4 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m47.1 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m48.8 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m50.5 124.6c.7-.2.7 1.4 0 1.2-.2-.1-.4-1 0-1.2z' />
      <path d='m52.3 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m54 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m55.7 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m57.4 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m59.1 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m60.8 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m62.5 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m64.2 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m65.9 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m67.6 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m69.3 124.6c.7-.2.7 1.4 0 1.2-.2-.1-.4-1 0-1.2z' />
      <path d='m71.1 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m72.8 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m74.5 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m76.2 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m77.9 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m79.6 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m81.3 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m83 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m84.7 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m86.4 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.4-1 0-1.2z' />
      <path d='m88.1 124.6c.7-.2.7 1.4 0 1.2-.2-.1-.4-1 0-1.2z' />
      <path d='m89.9 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m91.6 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m93.3 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m95 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m96.7 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m98.4 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m100.1 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m101.8 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m103.5 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m105.2 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.4-1 0-1.2z' />
      <path d='m106.9 124.6c.7-.2.7 1.4 0 1.2-.2-.1-.4-1 0-1.2z' />
      <path d='m108.7 124.6c.7-.2.7 1.4 0 1.2-.3-.1-.5-1 0-1.2z' />
      <path d='m44.6 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m46.3 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m48 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m49.7 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m51.4 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m53.1 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m54.8 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m56.5 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m58.3 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.1-.5.4-.6z' />
      <path d='m60 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m61.7 125.8c.3-.1.5.2.5.6 0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m63.4 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m65.1 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m66.8 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m68.5 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m70.2 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m71.9 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m73.6 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m75.3 125.8c.3-.1.5.2.5.6 0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m77.1 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.1-.5.4-.6z' />
      <path d='m78.8 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m80.5 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m82.2 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m83.9 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m85.6 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m87.3 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m89 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m90.7 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m92.4 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m94.1 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m95.9 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.1-.5.4-.6z' />
      <path d='m97.6 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m99.3 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m101 125.8c.3-.1.5.2.5.6 0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m102.7 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m104.4 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m106.1 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 0-.3.2-.5.4-.6z' />
      <path d='m107.8 125.8c.2-.1.4.1.5.4v.2c0 .8-.9.9-.9 0 .1-.3.2-.5.4-.6z' />
      <path d='m109.3 126v1c-.2-.2-.1-.8 0-1z' />
      <path d='m43.7 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m45.5 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m47.2 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m48.9 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m50.6 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m52.3 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m54 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m55.7 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m57.4 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m59.1 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m60.8 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m62.5 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m64.3 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m66 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m67.7 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m69.4 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m71.1 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m72.8 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m74.5 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m76.2 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m77.9 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m79.6 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m81.3 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m83.1 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m84.8 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m86.5 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m88.2 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m89.9 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m91.6 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m93.3 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m95 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m96.7 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m98.4 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m100.1 127.1c.7-.2.7 1.3.1 1.3-.5 0-.6-1.2-.1-1.3z' />
      <path d='m101.9 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m103.6 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m105.3 127.1c.7-.2.7 1.3.1 1.3s-.7-1.2-.1-1.3z' />
      <path d='m107 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m108.7 127.1c.7-.2.7 1.3.1 1.3-.5 0-.7-1.2-.1-1.3z' />
      <path d='m44.7 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m46.4 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m48.1 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m49.8 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m51.5 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m53.2 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m54.9 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m56.6 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m58.3 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m60 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m61.7 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m63.5 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m65.2 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m66.9 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m68.6 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m70.3 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m72 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m73.7 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m75.4 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m77.1 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m78.8 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m80.5 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.2-.4.4-.4z' />
      <path d='m82.3 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m84 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m85.7 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m87.4 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m89.1 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m90.8 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m92.5 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m94.2 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m95.9 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m97.6 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6.1-.2.1-.4.4-.4z' />
      <path d='m99.4 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m101.1 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m102.8 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m104.5 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m106.2 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m107.9 128.3c.3 0 .5.3.5.7v.3c-.2.8-1.1.4-.9-.6 0-.2.1-.4.4-.4z' />
      <path d='m109.3 128.4v1.1c-.2-.2-.2-.8 0-1.1z' />
      <path d='m44.2 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m45.9 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m47.7 130.6h-.8c-.5-1.3 1.1-1.4.8 0z' />
      <path d='m49.4 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m51.1 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m52.8 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m54.5 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m56.2 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m57.9 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m59.6 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m61.3 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m63 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m64.7 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m66.5 130.6h-.8c-.5-1.3 1.1-1.4.8 0z' />
      <path d='m68.2 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m69.9 130.6h-.9c-.4-1.3 1.3-1.4.9 0z' />
      <path d='m71.6 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m73.3 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m75 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m76.7 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m78.4 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m80.1 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m81.8 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m83.6 130.6h-.8c-.5-1.3 1.1-1.4.8 0z' />
      <path d='m85.3 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m87 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m88.7 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m90.4 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m92.1 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m93.8 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m95.5 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m97.2 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m98.9 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m100.6 130.6h-.8c-.4-1.3 1.2-1.4.8 0z' />
      <path d='m102.4 130.6h-.8c-.5-1.3 1.1-1.4.8 0z' />
      <path d='m104.1 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m105.8 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m107.5 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path d='m109.2 130.6h-.8c-.5-1.3 1.2-1.4.8 0z' />
      <path
        d='m67.1 37.2c2.6.2 4 2.5 7.1 2.3s5.6-3.1 8.7-2.8 18.9 2.5 22 15.8 3.4 37.2 3.4 37.2 3.4-15.8 3.4-28.8-4.8-28.5-4.8-28.5l-1.1 1.4s-4.5-7.6-6.2-9-4.2-2.5-4.2-2.5l1.7 2.5s-6.2-3.9-8.2-4.8-5.1-.8-5.1-.8l2.8 1.7s-6.2-1.4-7.3-2.5-3.9-3.4-3.9-3.4-5.4 4.8-7.1 5.4-5.1 2.2-5.1 2.2l2.8-4c-3.1.7-5.9 2.1-8.5 4-4.2 3.1-11.3 9.3-11.3 9.3l2.5-5.6s-6.5 3.4-8.7 10.7c-2.3 7.3-3.1 21.4-2.8 27.4.3 5.9 6.3 25.7 6.3 25.7s-4.1-33.9-.1-39.8 14.1-13.7 23.7-13.1z'
        fill='#666'
      />
      <path d='m69.1 65.1s-13.8-8.2-16.9-6.5-6.2 6.5-6.2 6.5 4-3.1 7.1-2.5 9.6 5.1 12.1 4.5c1.4-.3 2.7-1 3.9-2z' />
      <path d='m81.5 65.7s14.4-4.8 17.8-2.5 4.5 6.8 4.5 6.8-4.8-3.9-7.1-3.9c-2.2 0-11.3 3.4-13 2-1.7-1.6-2.2-2.4-2.2-2.4z' />
      <path d='m58.5 69.2c6.3 0 12.8.8 12.4 2.9-2.3 10.6-2.8 11.7-9.9 11.7-.7 0-1.4 0-2.2 0-11.4-.2-13.9-.2-14-9.1 0-4.5-.5-4.6 8.9-5.3 1.5-.1 3.2-.2 4.8-.2m0-.6c-1.6 0-3.3.1-4.8.2-4.7.3-6.9.5-8.1 1.2-1.5.8-1.4 2.2-1.4 4.2v.6c0 5.6 1.2 7.3 3.1 8.4 2.2 1.2 5.7 1.3 11.6 1.4h2.2c3.7 0 5.8-.3 7.2-2.1 1.4-1.7 2.1-4.8 3.3-10.1.1-.5 0-1-.3-1.3-1.8-2.3-9.5-2.5-12.8-2.5z' />
      <path
        d='m44.6 72.9h-1.4s-.2-2.3.3-2.6c.4-.2 3.9-.9 3.9-.9l-2.4 1.4z'
        fill='#231f20'
      />
      <path d='m90.4 69.2c1.7 0 3.3.1 4.8.2 9.4.6 8.9.9 8.9 5.3-.1 8.8-2.6 8.9-14 9.1-.8 0-1.5 0-2.2 0-7.1 0-7.6-1.1-9.9-11.7-.4-2 6.1-2.9 12.4-2.9m0-.6c-3.3 0-11 .2-12.7 2.3-.3.4-.4.9-.3 1.3 1.1 5.3 1.9 8.4 3.3 10.1 1.5 1.8 3.5 2.1 7.2 2.1h2.2c5.8-.1 9.3-.2 11.5-1.4 2.5-1.4 3-4.2 3.1-8.4 0-.2 0-.4 0-.6 0-2 0-3.4-1.4-4.2-1.2-.7-3.4-.9-8.1-1.2-1.5.1-3.2 0-4.8 0z' />
      <path
        d='m104.4 72.9h1.4s.2-2.3-.3-2.6c-.4-.2-3.9-.9-3.9-.9l2.4 1.4z'
        fill='#231f20'
      />
      <path d='m74.5 71.6c2.1 0 3 .1 3.3 1.7v-2l1.1-.8 3-1.2c-.1-.1-5.1 1.1-7.5 1-1.9 0-7.4-1.1-7.5-1l3 1.2 1.1.8v2c.5-1.7 1.4-1.7 3.5-1.7z' />
    </Icon>
  )
}

const Icon = styled.svg`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
`
